import React, { useContext, lazy, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { Layout } from './styles';
import appRoutes, { buildShowWallBoard } from './appRoutes';
import AccountContext from '../contexts/AccountContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import { SLIDE_OUT_ELEMENT_ID, FADE_IN_ELEMENT_ID } from '../constants';
import ALL_SETS_ROUTES from '../screens/DataManager/AllSets/routes';
import Loading from '../components/Loading';
import FlexCentered from '../components/Common/FlexCentered';
import FleetOpsSuspense from '../FleetOpsSuspense';
import SsoConfigurations from '../screens/Settings/SsoConfigurations';
import Microsoft from '../screens/Settings/SsoConfigurations/Microsoft';
import SPECIFIC_SET_ROUTES from '../screens/DataManager/SpecificSet/routes';
import SpecificSet from '../screens/DataManager/SpecificSet';
import AllSets from '../screens/DataManager/AllSets';
import DatasetDefinitionScreens from '../screens/DataManager/DatasetDefinitions';
import Metrics from '../screens/DataManager/Metrics';
import Search from '../screens/DataManager/Search';
import Costs from '../screens/DataManager/Costs';
import FeatureGate, { FEATURE_GATES } from '../components/FeatureGate';
import EntityDefinitions from '../screens/DataManager/EntityDefinitions';
import ValidationReport from '../screens/DataManager/SpecificSet/ValidationReport';
import GadgetForm from '../components/GadgetForm';
import V5GadgetForm from '../components/V5GadgetForm';
import MainNav from '../kingpin/navigation/MainNav';
import SettingsSideNav from '../kingpin/navigation/SettingsSideNav';
import UsersNav from '../screens/Settings/UsersNav';
import COSTS_SET_ROUTES from '../screens/DataManager/CostsSet/routes';
import ActivitySettingsCreation from '../screens/DataManager/Costs/Settings/ActivitySettingsCreation';
import ActivityGrid from '../screens/DataManager/Costs/CostsShow/ActivityGrid';
import CostsSet from 'screens/DataManager/CostsSet';
import OnboardingProvider from './OnboardingProvider';

const ReportsScreen = lazy(() => import('../screens/Reports'));
const DashboardsScreen = lazy(() => import('../screens/Dashboards'));
const ResetPasswordScreen = lazy(() => import('../screens/ResetPassword'));
const SignOutScreen = lazy(() => import('../screens/SignOut'));
const MyAccountScreen = lazy(() => import('../screens/MyAccount'));
const ShowUserSettingsScreen = lazy(
  () => import('../screens/Settings/ShowUser'),
);
const AllTargetCategoriesScreen = lazy(
  () => import('../screens/Settings/Targets/AllCategories'),
);
const TargetShowScreen = lazy(() => import('../screens/Settings/Targets/Show'));
const TargetCategoryShowScreen = lazy(
  () => import('../screens/Settings/Targets/CategoryShow'),
);
const BillingScreen = lazy(() => import('../screens/Settings/Billing'));
const IntegrationsScreen = lazy(
  () => import('../screens/Settings/Integrations'),
);
const IRGuideScreen = lazy(
  () => import('../screens/Settings/Integrations/IRGuide'),
);
const NotFoundPage = lazy(() => import('../screens/NotFoundPage'));
const ReportShow = lazy(() => import('../screens/ReportShow'));
const DashboardShow = lazy(() => import('../screens/DashboardShow'));
const ConnectionView = lazy(
  () => import('../screens/Settings/Integrations/ConnectionView'),
);
const DetailsScreen = lazy(() => import('../screens/OrderDetails'));
const BoardShowScreen = lazy(() => import('../screens/BoardShow'));
const BoardsScreen = lazy(() => import('../screens/Boards'));
const WallBoardBuilder = lazy(() => import('../screens/WallBoardIndex'));
const ShowWallBoardBuilder = lazy(
  () => import('../screens/WallBoardBuilderShow'),
);
const WallBoard = lazy(() => import('../screens/WallBoard'));
const WeatherBoard = lazy(() => import('../components/WeatherBoard'));
const WorkSpacesIndex = lazy(() => import('../screens/WorkSpacesIndex'));
const WorkSpacesShow = lazy(() => import('../screens/WorkSpacesShow'));
const DriverBonusShow = lazy(() => import('../screens/DriverBonusShow'));
const Home = lazy(() => import('../screens/Home'));
const OnBoardingLanding = lazy(() => import('../screens/OnBoarding/Landing'));
const OnBoardingBilling = lazy(() => import('../screens/OnBoarding/Billing'));
const IntercomSetup = lazy(() => import('../components/Common/IntercomSetup'));
const AdvancedSettings = lazy(() => import('../screens/Settings/Advanced'));
const GoalsScreen = lazy(() => import('../screens/Goals'));
const GoalShow = lazy(() => import('../screens/GoalShow'));
const SalesCommissionsScreen = lazy(
  () => import('../screens/SalesCommissions'),
);
const ScorecardsScreen = lazy(() => import('../screens/ScorecardsIndex'));
const ScorecardShowScreen = lazy(() => import('../screens/ScorecardShow'));
const GadgetBuilder = lazy(() => import('./GadgetBuilder'));
const PerformanceAppsIndex = lazy(
  () => import('../screens/PerformanceAppsIndex'),
);
const TargetsAppShow = lazy(() => import('../screens/TargetsAppShow'));
const EntityDetailsShow = lazy(() => import('../screens/EntityDetailsShow'));
const CopyContentScreen = lazy(() => import('../screens/CopyContent'));
const CopyContentHistoryScreen = lazy(
  () => import('../screens/CopyContentHistory'),
);

const Sidebar = () => (
  <Routes>
    <Route path={appRoutes.loggedIn.showWallBoard} element={null} />
    <Route path="*" element={<MainNav />} />
  </Routes>
);

export const AuthenticatedSwitch = () => (
  <Routes>
    <Route path={appRoutes.home} element={<Home />} />
    <Route path={appRoutes.acceptInvite} element={<Home />} />
    <Route path={'/weather'} element={<WeatherBoard />} />

    <Route path={appRoutes.loggedIn.kpiLists} element={<ScorecardsScreen />} />
    <Route
      path={appRoutes.loggedIn.showGlobalKpiList}
      element={<ScorecardShowScreen />}
    />

    <Route path={appRoutes.loggedIn.goals} element={<GoalsScreen />} />
    <Route path={appRoutes.loggedIn.showGlobalGoal} element={<GoalShow />} />
    <Route path={appRoutes.loggedIn.showCampaignGoal} element={<GoalShow />} />
    <Route
      path={appRoutes.loggedIn.showCampaignKpiList}
      element={<ScorecardShowScreen />}
    />
    <Route path={appRoutes.resetPassword} element={<ResetPasswordScreen />} />
    <Route path={appRoutes.loggedIn.reports} element={<ReportsScreen />} />
    <Route path={appRoutes.loggedIn.showReport} element={<ReportShow />} />
    <Route
      path={appRoutes.loggedIn.dashboards}
      element={<DashboardsScreen />}
    />
    <Route
      path={appRoutes.loggedIn.showDashboard}
      element={<DashboardShow />}
    />
    <Route path={appRoutes.loggedIn.signOut} element={<SignOutScreen />} />
    <Route path={appRoutes.loggedIn.myAccount} element={<MyAccountScreen />} />
    <Route path={appRoutes.loggedIn.settings} element={<SettingsSideNav />}>
      <Route path={appRoutes.loggedIn.allUsersSettings} element={<UsersNav />}>
        <Route
          path={appRoutes.loggedIn.showUserSettings}
          element={<ShowUserSettingsScreen />}
        />
      </Route>
      <Route
        path={appRoutes.loggedIn.allTargetCategories}
        element={<AllTargetCategoriesScreen />}
      />
      <Route
        path={appRoutes.loggedIn.targetCategoryShow}
        element={<TargetCategoryShowScreen />}
      />
      <Route
        path={appRoutes.loggedIn.targetShow}
        element={<TargetShowScreen />}
      />
      <Route
        path={appRoutes.loggedIn.advancedSettings}
        element={<AdvancedSettings />}
      />
      <Route
        path={appRoutes.loggedIn.commissions}
        element={<SalesCommissionsScreen />}
      />
      <Route
        path={appRoutes.loggedIn.billingSettings}
        element={<BillingScreen />}
      />
      <Route
        path={appRoutes.loggedIn.integrationsSettings}
        element={<IntegrationsScreen />}
      />
      <Route path={appRoutes.loggedIn.irGuide} element={<IRGuideScreen />} />
      <Route
        path={appRoutes.loggedIn.providerSettings}
        element={<ConnectionView />}
      />
      <Route
        path={appRoutes.loggedIn.ssoConfigurationsSettings}
        element={<SsoConfigurations />}
      />
      <Route
        path={appRoutes.loggedIn.microsoftSsoRedirect}
        element={<Microsoft.ConsentRedirect />}
      />
      <Route
        path={appRoutes.loggedIn.copyContent}
        element={<CopyContentScreen />}
      />
      <Route
        path={appRoutes.loggedIn.copyContentHistory}
        element={<CopyContentHistoryScreen />}
      />
    </Route>
    <Route
      path={appRoutes.loggedIn.showOrderDetails}
      element={<DetailsScreen />}
    />
    <Route path={appRoutes.loggedIn.showBoard} element={<BoardShowScreen />} />
    <Route
      path={appRoutes.loggedIn.v5GadgetBuilder}
      element={<GadgetBuilder />}
    >
      <Route
        path={appRoutes.loggedIn.v5GadgetBuilder}
        element={<GadgetForm />}
      />
      <Route
        path={appRoutes.loggedIn.v5GadgetBuilderShow}
        element={<V5GadgetForm isGadgetBuilder />}
      />
      <Route
        path={appRoutes.loggedIn.gadgetBuilderNew}
        element={<GadgetForm />}
      />
      <Route
        path={appRoutes.loggedIn.gadgetBuilderShow}
        element={<GadgetForm />}
      />
    </Route>
    <Route path={appRoutes.loggedIn.boards} element={<BoardsScreen />} />
    <Route
      path={appRoutes.loggedIn.wallBoardConfigs}
      element={<WallBoardBuilder />}
    />
    <Route
      path={appRoutes.loggedIn.showWallBoardConfig}
      element={<ShowWallBoardBuilder />}
    />
    <Route path={appRoutes.loggedIn.showWallBoard} element={<WallBoard />} />
    <Route
      path={appRoutes.loggedIn.driverBonus}
      element={<DriverBonusShow />}
    />

    <Route path={ALL_SETS_ROUTES.BASE} element={<AllSets />}>
      <Route
        path={ALL_SETS_ROUTES.DATASETS}
        element={<DatasetDefinitionScreens.DatasetsIndex />}
      />
      <Route
        path={ALL_SETS_ROUTES.METRICS}
        element={<Metrics mode="Metric" />}
      />

      <Route
        path={ALL_SETS_ROUTES.COMPOUND_METRICS}
        element={<Metrics mode="CompoundMetric" />}
      />
      <Route path={ALL_SETS_ROUTES.SEARCH} element={<Search />} />
      <Route path={ALL_SETS_ROUTES.COSTS} element={<Costs />} />
      <Route
        path={ALL_SETS_ROUTES.ENTITIES}
        element={
          <FeatureGate featureName={FEATURE_GATES.ENTITY_DETAILS}>
            <EntityDefinitions />
          </FeatureGate>
        }
      />
    </Route>
    <Route path={SPECIFIC_SET_ROUTES.BASE} element={<SpecificSet />}>
      <Route
        path={SPECIFIC_SET_ROUTES.FIELD_SETTINGS}
        element={<DatasetDefinitionScreens.DatasetShow />}
      />
      <Route
        path={SPECIFIC_SET_ROUTES.BASE}
        element={<Metrics mode={'Metric'} />}
      />
      <Route
        path={SPECIFIC_SET_ROUTES.METRICS}
        element={<Metrics mode={'Metric'} />}
      />
      <Route
        path={SPECIFIC_SET_ROUTES.COMPOUND_METRICS}
        element={<Metrics mode="CompoundMetric" />}
      />
      <Route path={SPECIFIC_SET_ROUTES.SHOW_SEARCH} element={<Search />} />

      <Route
        path={SPECIFIC_SET_ROUTES.SHOW_REPORT}
        element={<ValidationReport />}
      />
    </Route>
    <Route path={COSTS_SET_ROUTES.ACTIVITY_BASE} element={<CostsSet />}>
      <Route
        path={COSTS_SET_ROUTES.ACTIVITY_BASE_NEW}
        element={<ActivitySettingsCreation />}
      />
      <Route
        path={COSTS_SET_ROUTES.ACTIVITY_BASE_EDIT}
        element={<ActivitySettingsCreation />}
      />
      <Route path={COSTS_SET_ROUTES.ACTIVITY_BASE} element={<ActivityGrid />} />
    </Route>

    <Route
      path={appRoutes.loggedIn.showWorkSpace}
      element={<WorkSpacesShow />}
    />
    <Route
      path={appRoutes.loggedIn.showWorkSpaceTab}
      element={<WorkSpacesShow />}
    />
    <Route
      path={appRoutes.loggedIn.showPerformanceApp}
      element={<WorkSpacesShow />}
    />
    <Route
      path={appRoutes.loggedIn.showPerformanceAppTab}
      element={<WorkSpacesShow />}
    />
    <Route
      path={appRoutes.loggedIn.showTargetApp}
      element={<TargetsAppShow />}
    />
    <Route
      path={appRoutes.loggedIn.showTargetAppTasksTab}
      element={<TargetsAppShow />}
    />
    <Route
      path={appRoutes.loggedIn.showTargetAppContentTab}
      element={<TargetsAppShow />}
    />
    <Route
      path={appRoutes.loggedIn.showEntityApp}
      element={<EntityDetailsShow />}
    />
    <Route
      path={appRoutes.loggedIn.showEntityAppContentTab}
      element={<EntityDetailsShow />}
    />
    <Route path={appRoutes.loggedIn.workspaces} element={<WorkSpacesIndex />} />
    <Route
      path={appRoutes.loggedIn.performanceApps}
      element={<PerformanceAppsIndex />}
    />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

const Authenticated = () => (
  <div>
    <Layout>
      <Routes>
        <Route
          path={'*'}
          element={
            <>
              <Sidebar />
              <FleetOpsSuspense>
                <AuthenticatedSwitch />
              </FleetOpsSuspense>
              <div id={SLIDE_OUT_ELEMENT_ID} />
              <div id={FADE_IN_ELEMENT_ID} />
            </>
          }
        />
      </Routes>
    </Layout>
  </div>
);

const RedirectToOnBoardingLanding = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(appRoutes.onBoarding.landing);
  }, [navigate]);

  return null;
};

export const OnBoardingNavigator = () => (
  <OnboardingProvider>
    <Routes>
      <Route
        path={appRoutes.onBoarding.billing}
        element={<OnBoardingBilling />}
      />
      <Route
        path={appRoutes.onBoarding.provider}
        element={<ConnectionView />}
      />
      <Route path={appRoutes.onBoarding.irGuide} element={<IRGuideScreen />} />
      <Route
        path={appRoutes.onBoarding.landing}
        element={<OnBoardingLanding />}
      />
      <Route path="*" element={<RedirectToOnBoardingLanding />} />
    </Routes>
    <IntercomSetup />
  </OnboardingProvider>
);

const OnBoardingGate = () => {
  const { status } = useContext(AccountContext);
  const { isWallboardUser, wallBoardId, isFleetOpsStaff } =
    useContext(CurrentUserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isWallboardUser && wallBoardId) {
      if (location.pathname !== buildShowWallBoard(wallBoardId)) {
        navigate(buildShowWallBoard(wallBoardId));
      }
    }
  }, [isWallboardUser, location.pathname, navigate, wallBoardId]);

  if (!status) {
    return (
      <div style={{ height: '100vh' }}>
        <FlexCentered style={{ height: '100%' }}>
          <Loading />
        </FlexCentered>
      </div>
    );
  }

  if (status !== 'active' && !isFleetOpsStaff) {
    return <OnBoardingNavigator />;
  }

  return <Authenticated />;
};

export default OnBoardingGate;
