import { ColDef } from 'ag-grid-community';
import { useContext, useEffect, useState } from 'react';
import AccountContext from '../contexts/AccountContext';
import { defaultFormatter } from '../components/Grid/findCellValueFormatter';
import {
  CONTRACT_ON_TARGET_CELL_FIELD,
  CONTRACT_PROGRESS_CELL_FIELD,
  ON_TARGET_CELL_FIELD,
  PROGRESS_CELL_FIELD,
  PROGRESS_CELL_TYPE,
} from '../components/Grid/gridConstants';
import getBaseViewFields from '../components/Grid/getBaseViewFields';
import BoardContext from '../contexts/BoardContext';
import _ from 'lodash';
import isDefined from '../isDefined';
import isCustomerLaneBoard from '../isCustomerLaneBoard';
import isGeneralBoard from '../isBoardWithSlideOut';
import {
  DATE_CELL,
  DRILL_DOWN_CELL,
  ENTITY_CELL,
  GEO_POINT_CELL,
  NUMBER_CELL,
} from '../components/Grid/constants';
import { CUSTOMER_LANE_COMMITMENTS } from '../constants';
import PerformanceBoardContexts from '../components/PerformanceBoards/contexts';
import isPerformanceBoard from '../isPerformanceBoard';
import { PERFORMANCE_ENTITY_CELL } from '../components/PerformanceBoards/constants';
import { isPerformanceColumn } from './useBoardBaseView';
import EntityDefinitionsContext from '../contexts/EntityDefinitionsContext';
import { FleetOpsCellRendererParams } from '../types/agGrid';
import RolesContext from '../contexts/RolesContext';
import PERMISSIONS from '../permissionDefinitions';
import captureException from '../services/captureException';
import isDriverPerformanceBoard from '../isDriverPerformanceBoard';

export const getSpecialCellField = (cell: BoardSpecialCell): string => {
  if (cell.type === 'OnTarget') {
    return cell.headerName
      ? cell.headerName
      : `${cell.differenceField} - On Target`;
  } else {
    return cell.headerName
      ? cell.headerName
      : `${cell.differenceField} - Progress`;
  }
};

export const getType = (format: string) => {
  switch (format) {
    case 'Number':
    case 'Int':
    case 'float':
    case 'Float':
    case 'long':
    case 'Long':
    case 'Currency':
      return 'numericColumn';
    default:
      return undefined;
  }
};

export const getLaneCommitCols = () => [
  {
    field: PROGRESS_CELL_FIELD,
    type: 'numericColumn',
    valueFormatter: defaultFormatter,
    cellRenderer: 'progressCell',
    headerClass: 'rightAlignedHeader',
    minWidth: 80,
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    menuTabs: [],
    cellRendererParams: {
      cell: {
        type: PROGRESS_CELL_TYPE,
        cadenceField: 'cadence',
        totalField: 'totalLoads',
        targetField: 'commitment',
        differenceField: 'differenceInProgress',
        headerName: 'Progress',
        tooltipTitle: 'Loads Completed',
      },
    },
  },
  {
    field: CONTRACT_PROGRESS_CELL_FIELD,
    type: 'numericColumn',
    valueFormatter: defaultFormatter,
    cellRenderer: 'contractProgressCell',
    headerClass: 'rightAlignedHeader',
    minWidth: 80,
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    menuTabs: [],
  },
  {
    field: ON_TARGET_CELL_FIELD,
    type: 'numericColumn',
    valueFormatter: defaultFormatter,
    cellRenderer: 'onTargetCell',
    headerClass: 'rightAlignedHeader',
    minWidth: 80,
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellRendererParams: {
      cell: {
        type: 'OnTarget',
        differenceField: 'differenceInProgress',
        headerName: 'On Target',
      },
    },
    menuTabs: [],
  },
  {
    field: CONTRACT_ON_TARGET_CELL_FIELD,
    type: 'numericColumn',
    valueFormatter: defaultFormatter,
    cellRenderer: 'contractOnTargetCell',
    headerClass: 'rightAlignedHeader',
    minWidth: 80,
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    menuTabs: [],
  },
];

export const buildProgressCell = ({
  cell,
  baseView,
}: {
  cell: BoardProgressCell;
  baseView: FleetOps.BaseView;
}) => ({
  field: getSpecialCellField(cell),
  type: 'numericColumn',
  valueFormatter: defaultFormatter,
  cellRenderer: 'progressCell',
  minWidth: 80,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  cellRendererParams: {
    cell,
    fieldView: (() => {
      const maybeF = getBaseViewFields(baseView).find(
        (f) => f.field === cell.totalField,
      );
      if (maybeF) {
        return maybeF;
      }
      return undefined;
    })(),
  },
});

export const getCellRenderer = ({
  isLaneCell,
  fieldView,
  isCustomerLaneBoard,
  isPerformanceBoard,
  entityField,
  board,
  isEntityField,
}: {
  isLaneCell: boolean;
  fieldView: FleetOps.BaseViewField;
  isCustomerLaneBoard: boolean;
  isPerformanceBoard: boolean;
  entityField?: string;
  board: Board | PerformanceBoardTypes.Board;
  isEntityField: boolean;
}) => {
  if (isPerformanceBoard && !!entityField && fieldView.field === entityField) {
    return {
      cellRenderer: PERFORMANCE_ENTITY_CELL,
      isLeftAlign: true,
    };
  }
  if (
    board &&
    isGeneralBoard(board) &&
    fieldView.field === board.drillDownField
  ) {
    return {
      cellRenderer: DRILL_DOWN_CELL,
      isLeftAlign: true,
    };
  }

  if (isDriverPerformanceBoard(board) && fieldView.field === 'driver') {
    return { cellRenderer: 'driverCell', isLeftAlign: true };
  }

  return isEntityField
    ? { cellRenderer: ENTITY_CELL, isLeftAlign: true }
    : isLaneCell
      ? { cellRenderer: 'laneCell', isLeftAlign: true }
      : fieldView.field === 'orderNo' || fieldView.field === 'jobNo'
        ? { cellRenderer: 'jobCell', isLeftAlign: true }
        : fieldView.field === 'driver'
          ? { cellRenderer: 'driverCell', isLeftAlign: true }
          : fieldView.field === 'customer' && isCustomerLaneBoard
            ? { cellRenderer: 'customerWithHoverMenuCell', isLeftAlign: true }
            : fieldView.cellType === 'Status Flag'
              ? { cellRenderer: 'statusFlagCell', isLeftAlign: true }
              : fieldView.cellType === 'Currency'
                ? { cellRenderer: 'currencyCell', isLeftAlign: false }
                : fieldView.cellType === 'Percentage'
                  ? { cellRenderer: 'percentageCell', isLeftAlign: false }
                  : fieldView.cellType === 'Number'
                    ? { cellRenderer: NUMBER_CELL, isLeftAlign: false }
                    : fieldView.cellType === 'GeoPoint'
                      ? { cellRenderer: GEO_POINT_CELL, isLeftAlign: false }
                      : fieldView.cellType === 'Date'
                        ? { cellRenderer: DATE_CELL, isLeftAlign: true }
                        : fieldView.cellType === undefined &&
                            fieldView.aggFunc === 'sum'
                          ? { cellRenderer: NUMBER_CELL, isLeftAlign: false }
                          : fieldView.field === 'commissionStatus' &&
                              fieldView.dataset === 'commission'
                            ? {
                                cellRenderer: 'salesCommissionCell',
                                isLeftAlign: true,
                              }
                            : fieldView.field === 'reasonCode'
                              ? {
                                  cellRenderer: 'reasonCodeCell',
                                  isLeftAlign: true,
                                }
                              : { cellRenderer: undefined, isLeftAlign: true };
};

export const BULK_SALES_COMM_CELL = 'bulkSalesCell';

const BULK_SALES_COMM_COL_DEF = {
  cellRenderer: BULK_SALES_COMM_CELL,
  isLeftAlign: true,
};

const useColumnDefs = (
  baseView: FleetOps.BaseView,
  showInvisible?: boolean,
  sort?: SimpleGridSort[],
  disableAutoLayout?: boolean,
): ColDef[] => {
  const { currentPermissions } = useContext(RolesContext);
  const { board } = useContext(BoardContext);
  const { entityField: performanceEntityField } = useContext(
    PerformanceBoardContexts.SelectedEntity,
  );
  const { getEntityDefinitionFor } = useContext(EntityDefinitionsContext);
  const { unitsLocale } = useContext(AccountContext);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    const visibleFieldsBase = showInvisible
      ? getBaseViewFields(baseView)
      : getBaseViewFields(baseView).filter((field) => {
          return field && field.isVisible;
        });
    const visibleFields = baseView.fieldOrder
      .map((f) => visibleFieldsBase.find((vf) => vf.field === f))
      .filter((f) => !!f) as FleetOps.BaseViewField[];

    const newColDefs = visibleFields.map((fieldView) => {
      if (baseView.type === CUSTOMER_LANE_COMMITMENTS) {
        const sf = getLaneCommitCols().find(
          (c) => c.field === _.startCase(fieldView.field),
        );
        if (sf) {
          return sf;
        }
      }

      const isLaneCell =
        fieldView.field === 'lane' &&
        (fieldView.dataset === 'customerLaneCommits' ||
          fieldView.dataset === CUSTOMER_LANE_COMMITMENTS);

      const sortOrder = sort && sort.find((s) => s.field === fieldView.field);
      const entityDefinition = getEntityDefinitionFor(fieldView.field);
      const { cellRenderer, isLeftAlign } = getCellRenderer({
        isLaneCell,
        isCustomerLaneBoard: isCustomerLaneBoard(board),
        fieldView,
        isPerformanceBoard: isPerformanceBoard(board),
        entityField: performanceEntityField,
        board,
        isEntityField: !!entityDefinition,
      });
      const cellRendererParams: FleetOpsCellRendererParams = {
        isLeftAlign,
        fieldView,
        entityDefinition,
      };
      return {
        headerName: fieldView.nameAlias
          ? fieldView.nameAlias
          : _.startCase(fieldView.field),
        field: fieldView.field,
        sort: sortOrder ? sortOrder.direction : undefined,
        sortable: cellRenderer === GEO_POINT_CELL ? false : true,
        valueFormatter: defaultFormatter,
        type: getType(fieldView.type),
        menuTabs: [],
        wrapHeaderText: true,
        autoHeaderHeight: true,
        flex: disableAutoLayout ? 1 : undefined,
        minWidth: 80,
        rowGroup: false,
        enableRowGroup: true,
        editable: fieldView.field === 'reasonCode',
        cellEditor:
          fieldView.field === 'reasonCode' ? 'reasonCodePicker' : undefined,
        cellEditorPopup: fieldView.field === 'reasonCode',
        pinned: isCustomerLaneBoard(board)
          ? fieldView.field === 'customer' || fieldView.field === 'lane'
          : false,
        cellRenderer,
        headerClass: isLeftAlign ? undefined : 'rightAlignedHeader',
        cellRendererParams,
        valueGetter: (params) => {
          const dotSections = fieldView.field.split('.');
          if (dotSections.length === 2) {
            const lhs = dotSections[0];
            const rhs = dotSections[1];

            const values: string[] = [];
            const value = params.data[lhs] as
              | {
                  [key: string]: string | undefined;
                }[]
              | {
                  [key: string]: string | undefined;
                }
              | string
              | undefined;
            try {
              if (value === undefined) {
                return '-';
              } else if (Array.isArray(value)) {
                value.forEach((t) => {
                  const v = t[rhs];
                  if (v) {
                    values.push(v);
                  }
                });

                if (values.length === 0) {
                  return '-';
                }
                return values;
              } else if (typeof value === 'object') {
                const v = value[rhs];
                return v;
              } else {
                return value;
              }
            } catch (ex) {
              captureException(ex);
            }
          }

          return params.data[fieldView.field];
        },
      };
    }) as ColDef[];

    if (board && isGeneralBoard(board) && board.specialCells) {
      board.specialCells
        .map((cell) => {
          if (cell.type === 'OnTarget') {
            return {
              field: getSpecialCellField(cell),
              type: 'numericColumn',
              valueFormatter: defaultFormatter,
              cellRenderer: 'onTargetCell',
              minWidth: 80,
              wrapHeaderText: true,
              autoHeaderHeight: true,
              cellRendererParams: {
                cell,
                fieldView: (() => {
                  const maybeF = getBaseViewFields(baseView).find(
                    (f) => f.field === cell.differenceField,
                  );
                  if (maybeF) {
                    return maybeF;
                  }
                  return undefined;
                })(),
              },
            };
          }

          if (cell.type === PROGRESS_CELL_TYPE) {
            return buildProgressCell({
              cell,
              baseView,
            });
          }

          return undefined;
        })
        .filter(isDefined)
        .forEach((cell) => {
          newColDefs.push(cell);
        });
    }

    if (board) {
      const newDefs = [] as ColDef[];
      if (
        board.dataType === 'commission' &&
        currentPermissions.includes(PERMISSIONS.INTERNAL_USE.MODIFY_COMMISSIONS)
      ) {
        newDefs.push(BULK_SALES_COMM_COL_DEF);
      }
      newDefs.push(
        ...board.columnOrder
          .filter((col) => {
            if (isPerformanceColumn(col)) {
              return col.type === 'field';
            }

            return true;
          })
          .map((co) => {
            return newColDefs.find((cd) => cd.field === co.field && co.visible);
          })
          .filter(isDefined),
      );
      if (isPerformanceBoard(board)) {
        board.columnOrder
          .filter((f) => f.type === 'progress' && f.visible)
          .forEach((progressField) => {
            const cell: BoardProgressCell = {
              type: 'Progress',
              totalField: progressField.field,
              targetField: `${progressField.field}Target`,
              differenceField: `${progressField.field}Difference`,
              headerName: `${progressField.field} Progress`,
              tooltipTitle: `${progressField.field} Progress`,
            };
            newDefs.push(
              buildProgressCell({
                cell,
                baseView,
              }),
            );
          });
      }

      setColDefs(newDefs);
    } else {
      setColDefs(newColDefs);
    }
  }, [
    baseView,
    baseView.fields,
    baseView.type,
    board,
    disableAutoLayout,
    getEntityDefinitionFor,
    currentPermissions,
    performanceEntityField,
    showInvisible,
    sort,
    unitsLocale,
  ]);

  return colDefs;
};

export default useColumnDefs;
