import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GridApi,
  GridOptions,
  IServerSideDatasource,
} from 'ag-grid-community';

import BaseViewsContext from 'contexts/BaseViewsContext';
import StyleOverrides, {
  GRID_BORDERS_CSS,
  GRID_DARK_HEADER_CSS,
} from 'components/Grid/StyleOverrides';
import getBaseViewFields from 'components/Grid/getBaseViewFields';
import GridFrameworkComponents from 'components/Grid/GridFrameworkComponents';
import buildGridOptions from 'components/Grid/buildGridOptions';
import SlideOutContext from 'components/PerformanceBoards/contexts/SlideOutContext';
import useTotalsRow from 'components/PerformanceBoards/hooks/useTotalsRow';
import useEntityField from 'components/TargetManager/hooks/useEntityField';
import useDatasource from 'hooks/grids/useDatasource';
import { getCellRenderer, getType } from 'hooks/useColumnDefs';
import isDefined from 'isDefined';
import PerformanceBoardSlideOut from '../../PerformanceBoardSlideOut';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import { SLIDE_OUT_WIDTH } from 'components/PerformanceBoards/PerformanceBoardSlideOut/SlideOut';
import EntityDefinitionsContext from '../../../../contexts/EntityDefinitionsContext';
import { FleetOpsCellRendererParams } from '../../../../types/agGrid';
import { RowHeightContext } from '../../../../contextProviders/RowHeightProvider';
import { PERFORMANCE_STATUS_CELL } from '../../constants';
import { StatusCellProps } from './PerformanceStatusCell';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
`;

const Left = styled.div<{ isSlideOutOpen?: boolean }>`
  height: 100%;
  width: ${(props) =>
    props.isSlideOutOpen ? `calc(100% - ${SLIDE_OUT_WIDTH}px)` : '100%'};

  ${GRID_BORDERS_CSS}
  ${GRID_DARK_HEADER_CSS}
`;

const useColDefs = (
  board: PerformanceBoardTypes.Board,
  baseView: FleetOps.BaseView,
  sortOverride: SimpleGridSort,
) => {
  const { getEntityDefinitionFor } = useContext(EntityDefinitionsContext);
  const entityField = useEntityField(board.dataType);
  const { getFieldLabel } = useGetFieldLabel();

  const toColDef = useCallback(
    (colOrderItem: PerformanceBoardTypes.Column): ColDef | undefined => {
      const fieldView = getBaseViewFields(baseView).find(
        (f) => f.field === colOrderItem.field,
      );
      if (!fieldView) {
        return undefined;
      }

      if (colOrderItem.type === 'progress') {
        const cell: BoardProgressCell = {
          type: 'Progress',
          totalField: colOrderItem.field,
          targetField: `${colOrderItem.field}Target`,
          differenceField: `${colOrderItem.field}Difference`,
          headerName: `${getFieldLabel({
            field: colOrderItem.field,
            dataType: board.dataType,
          })} Progress`,
          tooltipTitle: `${getFieldLabel({
            field: colOrderItem.field,
            dataType: board.dataType,
          })} Progress`,
        };

        return {
          field: cell.headerName,
          type: 'numericColumn',
          cellRenderer: 'progressCell',
          cellRendererParams: {
            cell,
            fieldView,
          },
        };
      }

      if (colOrderItem.type === 'status') {
        const statusCell: StatusCellProps = {
          totalField: colOrderItem.field,
          targetField: `${colOrderItem.field}Target`,
          differenceField: `${colOrderItem.field}Difference`,
          dataset: baseView.type,
        };

        const cellRendererParams = {
          cell: statusCell,
        };

        return {
          field: getFieldLabel({
            field: colOrderItem.field,
            dataType: board.dataType,
          }),
          type: 'numericColumn',
          cellRenderer: PERFORMANCE_STATUS_CELL,
          cellRendererParams,
        };
      }

      const entityDefinition = getEntityDefinitionFor(fieldView.field);
      const { cellRenderer, isLeftAlign } = getCellRenderer({
        isLaneCell: false,
        isCustomerLaneBoard: false,
        isPerformanceBoard: true,
        entityField,
        board,
        fieldView,
        isEntityField: !!entityDefinition,
      });
      const cellRendererParams: FleetOpsCellRendererParams = {
        fieldView,
        entityDefinition,
        isLeftAlign,
      };

      return {
        field: colOrderItem.field,
        cellRenderer,
        cellRendererParams,
        initialSort:
          colOrderItem.field === sortOverride.field
            ? sortOverride.direction
            : undefined,
        headerName: fieldView.nameAlias
          ? fieldView.nameAlias
          : _.startCase(fieldView.field),
        type: getType(fieldView.type),
        menuTabs: [],

        rowGroup: false,
        enableRowGroup: true,
        editable: fieldView.field === 'reasonCode',
        cellEditor:
          fieldView.field === 'reasonCode' ? 'reasonCodePicker' : undefined,
        cellEditorPopup: fieldView.field === 'reasonCode',
        headerClass: isLeftAlign ? undefined : 'rightAlignedHeader',
      };
    },
    [
      baseView,
      board,
      entityField,
      getEntityDefinitionFor,
      getFieldLabel,
      sortOverride.direction,
      sortOverride.field,
    ],
  );

  const buildColDefs = useCallback(() => {
    return board.columnOrder
      .filter((c) => c.visible)
      .map(toColDef)
      .filter(isDefined);
  }, [board.columnOrder, toColDef]);
  const [colDefs, setColDefs] = useState<ColDef[]>(() => buildColDefs());
  useEffect(() => {
    setColDefs(buildColDefs());
  }, [buildColDefs]);

  return colDefs;
};

const useGridOptions = (
  dataSource: IServerSideDatasource | DataSource | undefined,
) => {
  const { rowHeight } = useContext(RowHeightContext);
  const buildNewGridOptions = useCallback(() => {
    if (!dataSource) {
      return undefined;
    }
    const gridOptions: GridOptions = {
      ...buildGridOptions({ rowHeight }),
      rowModelType: 'serverSide',
      popupParent: document.querySelector('body'),
    };

    return gridOptions;
  }, [dataSource, rowHeight]);
  const [gridOptions, setGridOptions] = useState<GridOptions | undefined>(() =>
    buildNewGridOptions(),
  );
  useEffect(() => {
    setGridOptions(buildNewGridOptions());
  }, [buildNewGridOptions]);

  return gridOptions;
};

const PerformanceBoardGrid = ({
  board,
  baseView,
  sortOverride,
  totalsRow,
  setGridApi,
}: {
  board: PerformanceBoardTypes.Board;
  baseView: FleetOps.BaseView;
  sortOverride: SimpleGridSort;
  totalsRow: any;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
}) => {
  const colDefs = useColDefs(board, baseView, sortOverride);
  const { dataSource } = useDatasource(undefined, baseView.type);
  const gridOptions = useGridOptions(dataSource);
  const { isOpen: isSlideOutOpen } = useContext(SlideOutContext);

  if (!gridOptions) {
    return null;
  }

  return (
    <Wrapper>
      <Left isSlideOutOpen={isSlideOutOpen}>
        <StyleOverrides
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              fontSize: '11px',
            }}
            className="ag-theme-balham ag-theme-fleetops"
          >
            <AgGridReact
              serverSideDatasource={dataSource}
              gridOptions={gridOptions}
              columnDefs={colDefs}
              components={GridFrameworkComponents}
              pinnedBottomRowData={totalsRow}
              onGridReady={(event) => {
                setGridApi(event.api);
              }}
            />
          </div>
        </StyleOverrides>
      </Left>
      <PerformanceBoardSlideOut />
    </Wrapper>
  );
};

const Gate = ({
  board,
  sortOverride,
  setGridApi,
}: {
  board: PerformanceBoardTypes.Board;
  sortOverride: SimpleGridSort;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
}) => {
  const { baseViews } = useContext(BaseViewsContext);
  const bv = baseViews[board.dataType];
  const totalsRow = useTotalsRow({ baseView: bv });

  if (!bv) {
    return null;
  }

  return (
    <PerformanceBoardGrid
      board={board}
      baseView={bv}
      sortOverride={sortOverride}
      totalsRow={totalsRow}
      setGridApi={setGridApi}
    />
  );
};

export default Gate;
