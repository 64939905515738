import React from 'react';
import ReportDrillDown from '../ReportDrillDown';
import AddReportDrillDownButton from '../AddReportDrillDownButton';
import Row from '../Common/Row';

const ReportDrillDowns = ({
  drillDowns,
  scope,
  isScope,
  isReport,
  isDashboardCard,
  onManualFilterChanged,
  onOpened,
  onFilterAdded,
  onFilterRemoved,
  testIdPrefix,
  label,
  isDisabled,
}: {
  drillDowns: FilterPlate[];
  scope: FilterPlate[];
  isScope?: boolean;
  isReport?: boolean;
  isDashboardCard?: boolean;
  onManualFilterChanged?: () => void;
  onOpened?: () => void;
  onFilterAdded?: () => void;
  onFilterRemoved?: () => void;
  testIdPrefix?: string;
  label?: string;
  isDisabled?: boolean;
}) => (
  <Row style={{ flexWrap: 'wrap', gap: 8 }}>
    {isScope && (
      <React.Fragment>
        {scope.map((d) => (
          <ReportDrillDown
            drillDown={d}
            key={d.id}
            isScope={isScope}
            isReport={isReport}
            isDashboardCard={isDashboardCard}
            onManualFilterChanged={onManualFilterChanged}
            onFilterRemoved={onFilterRemoved}
            isDisabled={isDisabled}
          />
        ))}
      </React.Fragment>
    )}
    {!isScope && (
      <React.Fragment>
        {drillDowns.map((d) => (
          <ReportDrillDown
            drillDown={d}
            key={d.id}
            isScope={false}
            isReport={isReport}
            isDashboardCard={isDashboardCard}
            onManualFilterChanged={onManualFilterChanged}
            onFilterRemoved={onFilterRemoved}
            isDisabled={isDisabled}
          />
        ))}
      </React.Fragment>
    )}

    <AddReportDrillDownButton
      isScope={!!isScope}
      isReport={isReport}
      onManualFilterChanged={onManualFilterChanged}
      onOpened={onOpened}
      onFilterAdded={onFilterAdded}
      testIdPrefix={testIdPrefix}
      label={label}
      isDisabled={isDisabled}
    />
  </Row>
);

export default ReportDrillDowns;
